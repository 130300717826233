import { Button, Result } from "antd";
import { signOut } from "firebase/auth";
import { parse } from "querystring";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import showdown from "showdown";
import { getProducts } from "../api";
import config from "../config";
import { getFirebaseAuth } from "../utils/getUserLogin";

// Example callback url
// https://pay-beta.soundon.fm/podcasts/ceb508d0-2bde-41de-bbfe-2a1ff222593e/callback?rec_trade_id=D20210331DsHCW0&status=0&auth_code=865756&bank_transaction_id=TP20210331DsHCW0&order_number=oR6exyj8f2PWnINOtkHMLfihGu3OGBFf
export default function DonateCallback() {
  const { podcastId } = useParams<{ podcastId: string }>();
  const location = useLocation();
  const qs = location.search.substring(1);
  const status = parse(qs).status;
  const planId = parse(qs).planId;

  const converter = new showdown.Converter();

  const [appreciation, setAppreciation] = useState<null | string>(null);

  useEffect(() => {
    if (podcastId === "f56a52a8-4a04-4936-affe-3eb8c5ac96db") {
      window.location.href = `https://tw.nextapple.com`;
    }
  }, []);

  useEffect(() => {
    if (!planId) {
      return;
    }
    const fetchProduct = async () => {
      const products = await getProducts(podcastId);
      const subscriptionPlan = products.find(
        (product) => product.id === planId
      );
      if (!subscriptionPlan) {
        return;
      }
      setAppreciation(subscriptionPlan.data.appreciation);
    };
    fetchProduct();
  }, [podcastId, planId]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        height: "100vh",
        alignItems: "center",
      }}
    >
      <Result
        status={status === "0" ? "success" : "error"}
        title={
          status === "0" ? (
            appreciation ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: converter.makeHtml(appreciation),
                }}
              ></div>
            ) : (
              "感謝你的贊助，我將繼續創作更多精彩的內容！"
            )
          ) : (
            "抱歉，贊助失敗"
          )
        }
        subTitle={status === "0" ? undefined : qs}
        extra={[
          <Button
            type="primary"
            size="large"
            onClick={() => {
              const auth = getFirebaseAuth();
              signOut(auth);
              window.location.href = `${config.playerUrl}/profile/subscription/${podcastId}/platform`;
            }}
          >
            立即前往收聽會員專屬內容
          </Button>,
          <Button
            size="large"
            onClick={() => {
              const auth = getFirebaseAuth();
              signOut(auth);
              window.location.href = `${config.playerUrl}/p/${podcastId}`;
            }}
          >
            前往 Podcast 節目
          </Button>,
        ]}
      />
    </div>
  );
}
